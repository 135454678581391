import React, { useCallback, useContext, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { FiFileText } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { link, mail } from '@sizle-icons';
import gotoCharts from '@sizle-icons/goto-charts.svg';
import options from '@sizle-icons/options.svg';
import { presentationPlaceholder } from 'src/design-system/icons'; // Ensure this is correctly imported
import { useModal } from 'src/modules/modals';
import { sendAmplitudeData } from 'src/utils/amplitude';
import PresentationOptionsPopupMenu from './PresentationOptionsPopupMenu';
import { workspaceContext, useUserContext } from 'src/context';
import { getFullName } from 'src/utils/user';
import { Box, Tooltip } from '@chakra-ui/core';

const PresentationItem = ({
  presentationId,
  presentation,
  originalFileUrl,
  originalFileType,
  owner,
  title,
  url,
  lastEdited,
  emailNotificationsEnabled,
  openTrackingEnabled,
  formEnabled,
  selectedLead, // lead recipient is locked if this is set
  onPresentationToggle
}) => {
  const navigate = useNavigate();
  const { avatarCacheTimestamp, workspaceIndex } = useUserContext();
  const { setModal } = useModal();
  const dismissModal = () => setModal(null);
  const { hasWorkspacePermission } = useContext(workspaceContext);
  const [optionsPopup, toggleOptionsPopup] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const triggerRef = useRef(null); // Add a ref to the trigger button

  const onClickPresentation = (e) => {
    e.preventDefault();
    closeOptionsPopup();
    if (hasWorkspacePermission('Analytics & Reports')) {
      navigate(`/s/${workspaceIndex}/presentation/${presentationId}/analytics`);
    }
  };

  const onClickOpenShareModal = () => {
    sendAmplitudeData('OPENING_SHARE_MODAL');
    setModal('share_presentation', {
      title,
      presentationId,
      openTrackingEnabled,
      emailNotificationsEnabled,
      closePopup: dismissModal
    });
  };

  const onClickAnalytics = (e) => {
    navigate(`/s/${workspaceIndex}/presentation/${presentationId}/analytics`);
  };

  const startMoving = useCallback((ev) => {
    ev.dataTransfer.setData('presentationId', presentationId);
  }, [presentationId]);

  const dragOver = useCallback((ev) => {
    ev.dataTransfer.dropEffect = 'move';
    ev.preventDefault();
  }, []);

  const closeOptionsPopup = useCallback((e) => {
    e && e.stopPropagation();
    toggleOptionsPopup(false);
  }, [toggleOptionsPopup]);

  const openOptionsPopup = useCallback((e) => {
    e.stopPropagation();
    toggleOptionsPopup(true);
  }, [toggleOptionsPopup]);

  return (
    <Element
      draggable={!optionsPopup}
      onDragStart={startMoving}
      onDragOver={dragOver}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}
      optionsPopup={optionsPopup}
    >
      {owner && (
        <AvatarOwner textAlign='right' pr='18px' className='avatar-owner'>
          <Tooltip label={getFullName(owner)} placement='left' zIndex='4'>
            <Box as='span'>
              <img className='avatar-small' size='sm' name={getFullName(owner)} src={`/api/users/${owner.auth0_id}/avatar?timestamp=${avatarCacheTimestamp.getMilliseconds()}`} />
            </Box>
          </Tooltip>
        </AvatarOwner>
      )}
      <PresentationThumbnail 
        className='presentation-thumbnail' 
        bg={`/api/documents/thumbnail/${presentationId}/0?timestamp=${presentation.updated_at}`} 
        onClick={(e) => onClickPresentation(e)}
      >
        {!presentation.updated_at && <FiFileText />}
        <GradientOverlay />
      </PresentationThumbnail>
      <PresentationDetails className='presentation-details'>
        <Title onClick={(e) => onClickPresentation(e)}>{title}</Title>
        <PresoControl className='preso-control'>
          <ShareButton onClick={onClickOpenShareModal}>
            Share
          </ShareButton>
          <AnalyticsButton onClick={onClickAnalytics}>
            Insights
          </AnalyticsButton>
          <PresentationOptions
            hidden={
              !hasWorkspacePermission('Share Documents') &&
              !hasWorkspacePermission('Add & Edit Documents') &&
              !hasWorkspacePermission('Analytics & Reports')
            }
          >
            <div draggable='false' className='popup-anchor'>
              <div
                className='button-icon'
                onClick={openOptionsPopup}
                style={{ WebkitMaskImage: `url(${options})`, width: '20px', height: '20px' }}
                ref={triggerRef} // Pass the ref here
              />
              {optionsPopup && <div className={`popup-container fullscreen ${optionsPopup ? '' : 'hidden'}`} onClick={closeOptionsPopup} />}
              <div className={`popup anchor-left ${optionsPopup ? '' : 'hidden'}`} style={{ zIndex: 1000 }}>
                <div className='popup-content tight'>
                  <PresentationOptionsPopupMenu
                    closePopup={closeOptionsPopup}
                    title={title}
                    url={url}
                    originalFileUrl={originalFileUrl}
                    originalFileType={originalFileType}
                    presentationId={presentationId}
                    formEnabled={formEnabled}
                    openTrackingEnabled={openTrackingEnabled}
                    emailNotificationsEnabled={emailNotificationsEnabled}
                    onClickAnalytics={(e) => onClickAnalytics(e)}
                    triggerRef={triggerRef} // Pass the ref to the menu
                  />
                </div>
              </div>
            </div>
          </PresentationOptions>
        </PresoControl>
      </PresentationDetails>
    </Element>
  );
}

const PresoControl = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure the buttons and options are spaced correctly */
  padding-top: 4px;
  transition: all 0.3s ease-in-out;

  > * {
    margin-left: 5px;
    margin-right: 5px;
  }

  .share-button-wrap {
    position: relative;
  }

  .button-icon {
    position: relative;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
    min-width: 12px;
    margin-left: 3rem;
    min-height: 12px;
    background-color: var(--text-color);

    &.primary { background-color: var(--text-color); }
    &:hover { opacity: 0.4; }
  }

  @media only screen and (max-width: 480px) {
    top: 60px;
  }
`;

const ShareButton = styled.button`
  background: var(--text-color);
  color: var(--background-color);
  border: none;
  border-radius: 6px;
  padding: 5px 13px 5px 13px;
  font-size: .75rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover { opacity: 0.8; }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--primary-color-vfaint);
  }
`;

const AnalyticsButton = styled.button`
  background: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 5px 13px 5px 13px;
  font-size: .75rem;
  font-weight: 600;
  justify-content: center;
  position: relative;
  transition: background 0.2s;
  text-decoration: none;
  &:hover {
    background: var(--background-highlight-color);
  }
}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--primary-color-vfaint);
  }

  /* Adaptation for dark mode */
  &:hover,
  &:focus {
    color: var(--white-color);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
`;

const Element = styled.div(({ isHovered, optionsPopup }) => css`
  width: 100%;
  height: auto;
  min-width: 200px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: flex-start;
  position: relative;
  border-radius: 12px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, z-index 0.2s ease-in-out;
  background-color: var(--background-sidebar);
  border: solid 1px var(--pane-border);
  padding-bottom: 10px;
  z-index: ${optionsPopup ? '1000' : isHovered ? '1' : '0'};
  box-shadow: ${isHovered ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.05)'};

  &:hover {
    transform: ${optionsPopup ? 'none' : 'scale(1.05)'};
    z-index: ${optionsPopup ? '1000' : '1'};
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  @media only screen and (max-width: 480px) {
    margin-bottom: 10px;
  }
`);


const PresentationThumbnail = styled.div(props => css`
  width: 100%;
  height: 120px;
  border-radius: 12px 12px 0 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  /* Two backgrounds: The image on top, gradient behind it */
  background: ${props.bg ? `url('${props.bg}'), linear-gradient(270deg, navy, purple, navy)` : `linear-gradient(270deg, navy, purple, navy)`};
  background-repeat: no-repeat, no-repeat;
  background-position: ${props.bg ? 'center center, 0% 50%' : '0% 50%'};
  background-size: ${props.bg ? 'cover, 600% 600%' : '600% 600%'};
  animation: gradientAnimation 20s ease infinite;

  ${!props.bg && css`
    &::after {
      content: '';
      display: block;
      width: 64px;
      height: 64px;
      background-color: var(--text-color);
      mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' viewBox='0 0 24 24'%3E%3Cpath d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'/%3E%3Cpolyline points='14 2 14 8 20 8'/%3E%3Cline x1='16' y1='13' x2='8' y2='13'/%3E%3Cline x1='16' y1='17' x2='8' y2='17'/%3E%3Cline x1='10' y1='9' x2='8' y2='9'/%3E%3C/svg%3E") no-repeat center / contain;
      -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' viewBox='0 0 24 24'%3E%3Cpath d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'/%3E%3Cpolyline points='14 2 14 8 20 8'/%3E%3Cline x1='16' y1='13' x2='8' y2='13'/%3E%3Cline x1='16' y1='17' x2='8' y2='17'/%3E%3Cline x1='10' y1='9' x2='8' y2='9'/%3E%3C/svg%3E") no-repeat center / contain;
    }

    &::before {
      content: 'No Preview Available';
      font-size: 12px;
      color: var(--text-color);
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
    }
  `}

  @keyframes gradientAnimation {
    0% {
      background-position: ${props.bg ? 'center center, 0% 50%' : '0% 50%'};
    }
    50% {
      background-position: ${props.bg ? 'center center, 100% 50%' : '100% 50%'};
    }
    100% {
      background-position: ${props.bg ? 'center center, 0% 50%' : '0% 50%'};
    }
  }
`);



const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 95%; /* Adjust the height as needed */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
`;

const PresentationDetails = styled.div`
  transition: all 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 10px 0 10px;
  position: relative;
  width: 100%;
`;

const AvatarOwner = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 99999;
  display: none;
`;

const Title = styled.div`
  font-size: 0.9rem;
  line-height: 1em;
  font-weight: 500;
  font-color: var(--text-color);
  text-align: left;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 12px;
  margin-bottom: 5px;
  margin-left: 6px;

  @media only screen and (max-width: 580px) {
    font-size: 14px;
  }
`;

const PresentationOptions = styled.div`
  cursor: pointer;
`;

const SelectedLeadContainer = styled.div`
  margin-top: 10px;
  margin-left: 10px;
`;

const SelectedLeadLabel = styled.div`
  font-weight: 600;
  color: var(--text-color);
`;

const LeadType = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: var(--text-color-muted);
`;

export default PresentationItem;
