import React, { useState, useContext, useEffect, useCallback } from 'react';
import { ProPopup } from 'src/modules/modals/components/ProPopup';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { HiOutlineSelector } from 'react-icons/hi';
import { FiPlusSquare } from 'react-icons/fi';
import { subscriptionContext, workspaceContext } from 'src/context';
import { useModal } from 'src/modules/modals';
import { useResponsive } from 'react-hooks-responsive';

const WorkspaceDropdown = ({ toggleNav }) => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { workspaces, workspaceIndex, workspace } = useContext(workspaceContext);
  const { currentPlan } = useContext(subscriptionContext);
  const [workspaceDropdownOpen, setWorkspaceDropdownOpen] = useState(false);
  const { track } = useAnalytics();
  const { setModal } = useModal();
  const breakpoints = { mobile: 0, desktop: 855 };
  const { screenIsAtMost, screenIsAtLeast } = useResponsive(breakpoints);
  const isMobile = screenIsAtLeast('mobile') && screenIsAtMost('desktop');

  const limitCreateMoreWorkspace = workspaces.filter(w => w.isAdmin).length >= (currentPlan?.workspaceCount || 0);

  const toggleDropdown = useCallback(() => {
    setWorkspaceDropdownOpen((prevState) => !prevState);
  }, []);

  const closeDropdown = useCallback(() => {
    setWorkspaceDropdownOpen(false);
  }, []);

  const navigateWorkspace = useCallback(
    (i) => (e) => {
      e.stopPropagation();
      setWorkspaceDropdownOpen(false);
      if (workspaces[i].isOverfilled && !workspaces[i].isAdmin) {
        setModal('overfilled', {
          adminEmail: workspaces[i].workspace_members.find((u) => u.member_type.value === 'admin').user.email,
        });
      } else {
        navigate(`/s/${i}/presentations`);
        if (isMobile) toggleNav('close');
        track('switch-workspace', {
          from: workspaceIndex,
          to: i,
        });
      }
    },
    [isMobile, navigate, setModal, toggleNav, track, workspaceIndex, workspaces]
  );

  useEffect(() => {
    if (hash === '#create-workspace') {
      setTimeout(() => setWorkspaceDropdownOpen(true), 1);
    }
  }, [hash]);

  const onClickCreateNewWorkspace = (e) => {
    e.stopPropagation();
    setWorkspaceDropdownOpen(false);
    setModal('create_workspace');
    track('click-create-workspace');
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.workspace-dropdown')) {
        closeDropdown();
      }
    };

    if (workspaceDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [workspaceDropdownOpen, closeDropdown]);

  return workspaces && workspaces.length > 0 ? (
    <div className="workspace-dropdown" onClick={toggleDropdown}>
      <img
        className="avatar-small"
        src={`/api/workspaces/${workspace?.workspace_id}/avatar?timestamp=${workspace?.updated_at}`}
        alt="Workspace Avatar"
      />
      <label>
        <span className="name">{workspace?.name}</span>
      </label>
      <HiOutlineSelector className="decoration" />
      {workspaceDropdownOpen && (
        <>
          <div className="select-list">
            {workspaces.map((w, i) => (
              <div className="menu-item" key={w.workspace_id} onClick={navigateWorkspace(i)}>
                <img
                  className="avatar-small"
                  title={w?.name}
                  src={`/api/workspaces/${w?.workspace_id}/avatar?timestamp=${w?.updated_at}`}
                  alt="Workspace"
                />
                <span className="name">{w?.name}</span>
              </div>
            ))}
            <ProPopup title="Workspace limit reached" subtitle="Upgrade to create more workspaces"  active={currentPlan?.plan_id === 'SOLO_TRIAL' || limitCreateMoreWorkspace}>
              <div className="menu-item create-workspace" onClick={onClickCreateNewWorkspace}>
                <FiPlusSquare title="Create new workspace" />
                <span className="name">Create new</span>
              </div>
            </ProPopup>
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default WorkspaceDropdown;
