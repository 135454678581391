import React, { useRef, useState, useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useUserContext, workspaceContext } from 'src/context'; // Import correct contexts
import PresentationItem from './PresentationItem'; // Ensure this path is correct

const CustomTable = ({ presentations = [] }) => {
  const { t } = useTranslation();
  const { setSearchPresentations } = useUserContext();
  const { uploadDocument } = React.useContext(workspaceContext); // Use workspace context for file upload logic

  const fileInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('title');

  // Memoized function for filtering and sorting presentations
  const filteredPresentations = useMemo(() => {
    return presentations
      .filter((item) =>
        !searchTerm ||
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        switch (sortOrder) {
          case 'title':
            return a.title.localeCompare(b.title);
          case 'created_at':
            return new Date(b.created_at) - new Date(a.created_at);
          case 'updated_at':
            return new Date(b.updated_at) - new Date(a.updated_at);
          default:
            return 0;
        }
      });
  }, [presentations, searchTerm, sortOrder]);

  // Trigger hidden file input click
  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    try {
      if (event.target.files[0]) {
        await uploadDocument(event.target.files[0]);
        console.log('File uploaded successfully');
      }
    } catch (error) {
      console.error('File upload failed:', error.message);
    }
  };

  // Callback for handling search input
  const handleSearchInput = useCallback(
    (event) => setSearchTerm(event.target.value),
    []
  );

  // Callback for clearing search
  const handleClearSearch = useCallback(() => setSearchTerm(''), []);

  // Callback for changing sort order
  const handleSortOrderChange = useCallback(
    (event) => setSortOrder(event.target.value),
    []
  );

  if (presentations.length === 0) {
    return (
      <EmptyStateContainer>
        <CenterMessage>
          <h2>This folder is empty</h2>
          <p>Let’s upload a document and get started!</p>
        </CenterMessage>
        <HiddenFileInput
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
        />
        <UploadButton
          className="btn btn-primary"
          title="Upload a document"
          onClick={handleFileUploadClick}
        >
          Start by uploading a file
        </UploadButton>
      </EmptyStateContainer>
    );
  }

  return (
    <div className="custom-table">
      <TableControls>
        <LeftControls>
          <SearchContainer>
            <FormInput
              type="text"
              value={searchTerm}
              placeholder={t('inputs.Filter by title')}
              onChange={handleSearchInput}
              aria-label="Search presentations by title"
            />
            {searchTerm && (
              <ClearButton onClick={handleClearSearch} aria-label="Clear search">
                X
              </ClearButton>
            )}
          </SearchContainer>
          <SortDropdown
            value={sortOrder}
            onChange={handleSortOrderChange}
            aria-label="Sort presentations"
          >
            <option value="title">Sort by Title</option>
            <option value="created_at">Sort by Date Created</option>
            <option value="updated_at">Sort by Last Edited</option>
          </SortDropdown>
        </LeftControls>
      </TableControls>
      <Table>
        {filteredPresentations.map((presentation) => (
          <PresentationItem
            key={presentation.presentationId}
            presentation={presentation}
            published={presentation.published}
            originalFileUrl={presentation.originalFileUrl}
            originalFileType={presentation.originalFileType}
            owner={presentation.owner}
            createAt={presentation.created_at}
            lastEdited={presentation.updated_at || presentation.created_at}
            presentationId={presentation.presentationId}
            formEnabled={presentation.formEnabled}
            emailNotificationsEnabled={presentation.emailNotificationsEnabled}
            openTrackingEnabled={presentation.openTrackingEnabled}
            thumbnail={`/api/documents/thumbnail/${presentation.presentationId}/0?timestamp=${presentation.updated_at}`}
            url={presentation.url}
            title={presentation.title}
          />
        ))}
      </Table>
    </div>
  );
};

CustomTable.propTypes = {
  presentations: PropTypes.array.isRequired,
};

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
`;

const CenterMessage = styled.div`
  margin-bottom: 20px;

  h2 {
    font-size: 2.3rem;
    font-weight: bold;
    color: var(--text-color);
  }

  p {
    font-size: 1.2rem;
    padding-top: 10px;
    color: var(--text-muted-color);
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const UploadButton = styled.button`
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  background-color: var(--primary-color);
  padding: 12px 24px;
  border-radius: 7px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: var(--primary-hover-color);
    transform: translateY(-2px);
  }
`;

const TableControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const LeftControls = styled.div`
  display: flex;
  align-items: center;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--input-background-color);
  border-radius: 14px;
  padding: 5px;
`;

const ClearButton = styled.div`
  margin-left: 10px;
  padding: 4px 10px;
  background-color: var(--primary-color);
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
`;

const FormInput = styled.input`
  width: 100%;
  max-width: 300px;
  border-radius: 6px;
  border: solid 1px var(--input-border-color);
  background-color: var(--input-background-color);
  padding: 8px;
  font-size: 14px;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

  &:focus {
    background-color: var(--input-focus);
    border-color: var(--input-focus-border);
    outline: none;
  }
`;

const SortDropdown = styled.select`
  display: flex;
  align-items: center;
  min-width: 150px;
  border-radius: 4px;
  padding: 8px 10px;
  margin-left: 20px;
  color: var(--text-color);
  font-size: 14px;
  cursor: pointer;
  background-color: var(--input-background-color);
  &:hover {
    background-color: var(--input-focus);
  }
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 12px;
  margin-top: 15px;
  margin-right: 2%;
`;

export default CustomTable;
