import React from 'react'
import { PropTypes } from 'prop-types'
import styled from '@emotion/styled'

const ShadowedInput = ({ placeholder, error, Icon, large, enableInnerOnChange, onBlur, onChange, name, ...rest }) => {
  const handleBlur = (e) => {
    if (onBlur) onBlur(e)
  }

  const handleTextChange = (e) => {
    if (enableInnerOnChange && onChange) {
      onChange && onChange(e.target.value, name)
    } else {
      onChange && onChange(e)
    }
  }

  return (
    <Wrapper>
      {Icon && (
        <IconWrapper>
          <Icon
            width='16px'
            height='16px'
          />
        </IconWrapper>
      )}
      <Element
        {...rest}
        placeholder={placeholder}
        name={name}
        onChange={handleTextChange}
        onBlur={handleBlur}
        icon={Icon}
        error={error}
        large={large}
      />
    </Wrapper>
  )
}

ShadowedInput.defaultProps = {
  enableInnerOnChange: true
}

ShadowedInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  Icon: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  enableInnerOnChange: PropTypes.bool,
  error: PropTypes.bool,
  large: PropTypes.bool
}

const Wrapper = styled.div`
  position: relative;
`

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  
  .SVGInline {
    g {
      // stroke: ${props => props.theme.brand.grey};
    }
  }
`

const Element = styled.input`
  width: 100%;
  height: ${props => props.large ? '47px' : '30px'};
  padding: ${props => props.icon ? '5px 12px 5px 39px' : '5px 12px'};
  border: none;
  border-radius: 6px;
  box-shadow: ${props => props.theme.inputs.boxShadow};
  background: ${props => props.theme.inputs.background};
  transition: 0.2s;
  opacity: ${props => props.disabled ? 0.4 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};
  font-family: ${props => props.theme.typo.fontDefault};
  font-size: ${props => props.large ? '19px' : 'inherit'};
  
  &:focus {
    box-shadow: inset 0 5px 6px 0 rgba(0, 0, 0, 0.12);
  }
  &:invalid {
    box-shadow: inset 0 5px 6px 0 rgb(255 0 0 / 20%);
  }
`

export default ShadowedInput
