import React, { useState, useContext, useEffect } from 'react'
import { NumberInput, NumberInputField, NumberInputStepper, NumberDecrementStepper, NumberIncrementStepper } from '@chakra-ui/core'
import { PermissionsSelect, InviteWorkspaceInput, InvitationLink } from 'src/modules/workspace'
import { Link, useNavigate } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { workspaceContext, subscriptionContext, useUserContext } from 'src/context'
import { sendAmplitudeData } from 'src/utils/amplitude'

import { CustomDomainForm } from 'src/modules/workspace/custom-domain-form/CustomDomainForm.tsx'
import CustomBrandingForm from '../../modules/workspace/custom-branding-form/CustomBrandingForm.tsx'

const WorkspaceSettingsPage = () => {
  const { user } = useUserContext()
  const { hasWorkspacePermission, currentWorkspaceSeatPlan, currentWorkspacePlan, previewWorkspaceSeatCost, updateWorkspaceSeats, workspace, removeUser, removeInvite, updateWorkspaceName, updateWorkspaceAvatar } = useContext(workspaceContext)
  const { paymentMethod } = useContext(subscriptionContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [workspaceNameField, setWorkspaceNameField] = useState('')
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const [nameUpdating, setNameUpdating] = useState(false)
  const [avatarUpdating, setAvatarUpdating] = useState(false)
  const [confirmingRemoveInvite, setConfirmingRemoveInvite] = useState(null)
  const [confirmingRemoveUser, setConfirmingRemoveUser] = useState(null)
  const [confirmingQuit, setConfirmingQuit] = useState(false)
  const [loadingRemoveInvite, setLoadingRemoveInvite] = useState(null)
  const [loadingRemoveUser, setLoadingRemoveUser] = useState(null)
  const [loadingQuit, setLoadingQuit] = useState(false)
  const [pendingSeats, setPendingSeats] = useState(0)
  const [updatingSeats, setUpdatingSeats] = useState(false)
  const [seatUpdateCost, setSeatUpdateCost] = useState(null)
  const [loadingSeatCost, setLoadingSeatCost] = useState(0)
  const [updateSeatCoastDebounce, setUpdateSeatCostDebounce] = useState(null)
  
  // New state for custom URL visibility
  const [customURLEnabled, setCustomURLEnabled] = useState(false)

  useEffect(() => {
    setWorkspaceNameField(workspace?.name || '')
    setPendingSeats(getPurchasedSeats())
  }, [workspace])

  useEffect(() => {
    sendAmplitudeData('PAGE_VIEW_WORKSPACE_SETTINGS')
  }, [])

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }
    setDebounceTimeout(setTimeout(async () => {
      if (workspaceNameField && workspaceNameField !== workspace.name) {
        setNameUpdating(true)
        try {
          await updateWorkspaceName(workspaceNameField)
          toastr.success(t('toastr.Your workspace name has been updated successfully'))
          sendAmplitudeData('WORKSPACE_NAME_UPDATED')
        } catch (e) {
          toastr.error(t('toastr.An error occurred while updating your workspace name'))
        }
        setNameUpdating(false)
      }
    }, 500))
  }, [workspaceNameField])

  useEffect(() => {
    clearTimeout(updateSeatCoastDebounce)
    if (paymentMethod) {
      setLoadingSeatCost(true)
      setUpdateSeatCostDebounce(setTimeout(() => {
        if (getSeatDiff() !== 0) {
          previewWorkspaceSeatCost(pendingSeats)
            .then((cost) => {
              setSeatUpdateCost(cost)
              setLoadingSeatCost(false)
            })
        } else {
          setSeatUpdateCost(0)
        }
      }, 500))
    }
  }, [pendingSeats])

  // Set the customURLEnabled state if workspace.customURLEnabled is true
  useEffect(() => {
    if (workspace?.customURLEnabled) {
      setCustomURLEnabled(true)
    }
  }, [workspace])

  const submitWorkspaceAvatar = async (e) => {
    setAvatarUpdating(true)
    try {
      await updateWorkspaceAvatar(e.target.files[0])
      toastr.success(t('toastr.Your workspace icon has been updated successfully'))
      sendAmplitudeData('WORKSPACE_AVATAR_UPDATED')
    } catch (e) {
      toastr.error(t('toastr.An error occurred while updating your workspace icon'))
      throw e
    }
    setAvatarUpdating(false)
  }

  const confirmRemoveInvite = async () => {
    setLoadingRemoveInvite(confirmingRemoveInvite)
    try {
      await removeInvite(confirmingRemoveInvite)
      toastr.info('User invite has been revoked')
      sendAmplitudeData('USER_INVITE_REVOKED_FROM_WORKSPACE')
    } catch (e) {
      toastr.success('User was an issue revoking this user\'s invite, please try again later')
      throw e
    }
    setLoadingRemoveInvite(null)
    setConfirmingRemoveInvite(null)
  }
  const confirmRemoveUser = async () => {
    setLoadingRemoveUser(confirmingRemoveUser)
    try {
      await removeUser(confirmingRemoveUser)
      toastr.info('User has been removed from the workspace')
      sendAmplitudeData('USER_REMOVED_FROM_WORKSPACE')
    } catch (e) {
      toastr.error('There was an issue removing this user from the workspace. Please try again later.')
      throw e
    }
    setLoadingRemoveUser(null)
    setConfirmingRemoveUser(null)
  }

  const updateSeatSubscription = async () => {
    setUpdatingSeats(true)
    try {
      await updateWorkspaceSeats(pendingSeats)
      toastr.success('Your workspace seat subscription has been updated')
      sendAmplitudeData('WORKSPACE_SEAT_SUBSCRIPTION_UPDATED')
    } catch (e) {
      toastr.error('There was an issue udating your workspace subscription. Please try again later.')
      throw e
    }
    setUpdatingSeats(false)
  }

  const confirmQuit = async () => {
    setLoadingQuit(true)
    try {
      await removeUser(user.sub)
      toastr.info('You have quit the workspace')
      sendAmplitudeData('USER_REMOVED_FROM_WORKSPACE')
    } catch (e) {
      toastr.error('There was an issue while quitting the workspace. Please try again later.')
      throw e
    }
    navigate('/s/0/presentations', { replace: true })
  }

  const unacceptedInvites = () => workspace?.invites.filter(i => i.has_accepted === null)
  const seatCount = () => workspace?.workspace_members?.length
  const inviteCount = () => (unacceptedInvites() || []).length
  const freeSeats = currentWorkspacePlan?.seatCount
  const workspaceIsPro = () => workspace?.isProWorkspace
  const getPurchasedSeats = () => (workspace?.subscription?.purchasedSeats) || 0
  const getAvailableSeats = () => freeSeats + (workspace?.subscription?.purchasedSeats || 0)
  const tooManyInvites = () => (seatCount() + inviteCount()) > getAvailableSeats()
  const tooManySeats = () => seatCount() > getAvailableSeats()
  const getMemberName = (member) => member.user && (((member.user.given_name || member.user.family_name) && `${member.user.given_name || ''} ${member.user.family_name || ''}`) || member.user.name)
  const getMemberEmail = (member) => member.user?.email || member.email_address
  const isMe = (member) => getMemberEmail(member) === user?.email
  const isConfirmingInviteRemove = member => member.invite_id === confirmingRemoveInvite
  const isConfirmingUserRemove = member => member.user_id === confirmingRemoveUser
  const workspaceUsersAndInvites = () => (workspace && [...workspace.workspace_members, ...unacceptedInvites()]) || []
  const isMemberAnInvite = (member) => !!member.invite_id
  const amWorkspaceAdmin = () => workspace?.isAdmin
  const isOwner = (member) => member.user_id === workspace.owner.auth0_id
  const getSeatDiff = () => pendingSeats - getPurchasedSeats()
  const getSeatIncrement = () => Math.max(getSeatDiff(), 0)
  const getSeatUpdateCost = () => `$${(pendingSeats * currentWorkspaceSeatPlan.cost / 100)}`
  const getSeatUpdateTime = () => seatUpdateCost ? format(parseInt(seatUpdateCost.billDate * 1000), 'do MMM yyyy') : ''

  return (
    <div className='page-container workspace-settings-page'>
      <div className='page-section'>
        <div className='ws-details'>
          <label className='ws-icon-label label big'>Workspace avatar</label>
          <div className='avatar-image'>
            <img className='avatar' name={workspace?.name} src={workspace ? `/api/workspaces/${workspace?.workspace_id}/avatar?timestamp=${workspace?.updated_at}` : ''} />
            <div>
              <input disabled={!hasWorkspacePermission('Workspace Settings')} onChange={submitWorkspaceAvatar} id='workspace-avatar' name='avatar' type='file' />
              <label className='btn secondary' htmlFor='workspace-avatar' disabled={avatarUpdating || !hasWorkspacePermission('Workspace Settings')} loading={avatarUpdating ? 'loading' : undefined}>Change</label>
            </div>
          </div>
          <label className='ws-details-label label big'></label>
          <div className='ws-name'>
            <label className='label label'>Name</label>
            <div className={`${nameUpdating && 'loading-spinner'}`}>
              <input disabled={nameUpdating || !hasWorkspacePermission('Workspace Settings')} className='input-text' onChange={(e) => setWorkspaceNameField(e.target.value)} value={workspaceNameField} />
            </div>
          </div>
        </div>
      </div>
      <div className='page-section-header seats-header'>
        Invite users to workspace
        {currentWorkspacePlan.seatCount != null && (
          <div className='ws-seats'>
            <div>
              <label className='top-left'>Used seats</label>
              <label><b>{seatCount()}</b> of {getAvailableSeats()}</label>
              <div className='seat-bar'>
                <div title='Seats used' style={{ width: `${(seatCount() / getAvailableSeats()) * 100}%` }} className={`${tooManySeats() && 'danger'} seat-bar-seats`} />
                <div title='Pending invites' style={{ width: `${(inviteCount() / getAvailableSeats()) * 100}%` }} className={`${tooManyInvites() && 'danger'} seat-bar-invites`} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='page-section'>
        <div className='member-table'>
          {workspace && workspaceUsersAndInvites().map((member) => {
            return (
              <div key={member-`${getMemberEmail(member)}`} className='member-row'>
                <div className='member-name'>
                  <img className='avatar-small' src={`/api/users/${member.user_id}/avatar?timestamp=${member.user?.updated_at}`} />
                  <div>
                    {getMemberName(member) && <span className='name'>{getMemberName(member)}</span>}
                    <span className='label-faint'>{getMemberEmail(member)}</span>
                  </div>
                </div>
                <div className='member-role'>
                  <PermissionsSelect workspaceMember={member} isInvite={!!member.invite_id} />
                </div>
                <div className='actions'>
                  {hasWorkspacePermission('Workspace Settings') && !isMe(member) && (
                    (isConfirmingInviteRemove(member) &&
                      <>
                        <button className='btn' onClick={() => setConfirmingRemoveInvite(null)}>Cancel</button>
                        <button className='btn danger' onClick={() => confirmRemoveInvite()} loading={loadingRemoveInvite === member.invite_id ? 'loading' : undefined}>Yes, I'm sure</button>
                      </>) ||
                    (isConfirmingUserRemove(member) &&
                      <>
                        <button className='btn' onClick={() => setConfirmingRemoveUser(null)}>Cancel</button>
                        <button className='btn danger' onClick={() => confirmRemoveUser()} loading={loadingRemoveUser === member.user_id ? 'loading' : undefined}>Yes, I'm sure</button>
                      </>) ||
                        <>
                          {isMemberAnInvite(member) && <button className='btn danger' onClick={() => setConfirmingRemoveInvite(member.invite_id)}>Cancel Invite</button>}
                          {!isMemberAnInvite(member) && <button className='btn danger' onClick={() => setConfirmingRemoveUser(member.user_id)}>Remove User</button>}
                        </>
                  )}
                  {isMe(member) && !isOwner(member) && (
                    confirmingQuit
                      ? (
                        <>
                          <button className='btn' onClick={() => setConfirmingQuit(false)}>Cancel</button>
                          <button className='btn danger' onClick={confirmQuit} loading={loadingQuit ? 'loading' : undefined}>Yes, I'm sure</button>
                        </>
                      )
                      : (
                        <button className='btn danger' onClick={() => setConfirmingQuit(true)}>Quit</button>
                      )
                  )}
                </div>
              </div>
            )
          })}
        </div>
        {hasWorkspacePermission('Workspace Settings') && (
          <>
            <InviteWorkspaceInput onAddSeats={() => setPendingSeats(pendingSeats + 1)} />
            <InvitationLink onAddSeats={() => setPendingSeats(pendingSeats + 1)} />
          </>
        )}
      </div>
      {amWorkspaceAdmin() && (
        <>
          {currentWorkspacePlan.seatCount != null && (
            <>
              <div className='page-section-header'>Add or remove user seats</div>
              {workspaceIsPro() &&
                <div className='page-section'>
                  <label className='label big'>Your plan includes <span className='label big blue'>{freeSeats} {freeSeats === 1 ? 'seat' : 'seats'}</span> per workspace. You can add additional seats to invite more users below.</label>
                  <div id='add-seats' className='seat-settings'>
                    <div className={`cost-section ${pendingSeats !== 0 && 'expanded'}`}>
                      <label className='cost'>${(currentWorkspaceSeatPlan.cost / 100 * pendingSeats).toFixed(2)}</label>
                      <label className='label-faint big'>Billed monthly</label>
                      <label className='label-faint big'>${(currentWorkspaceSeatPlan.cost / 100).toFixed(2)} per additional seat</label>
                    </div>
                    <div className='seat-input'>
                      <NumberInput defaultValue={0} size='lg' min={0} max={20} value={pendingSeats} onChange={setPendingSeats} step={1}>
                        <NumberInputField width='80px' />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </div>
                  </div>
                  {pendingSeats !== getPurchasedSeats() &&
                    <div>
                      {paymentMethod && (
                        <>
                          <span className='label big'>
                            {pendingSeats > getPurchasedSeats() && <>You will be charged for {getSeatIncrement()} additional seats bringing you up to a total of {pendingSeats} for the <span className='label big blue'>{workspace.name}</span> workspace.<br /></>}
                            {pendingSeats < getPurchasedSeats() && <>Your subscription will be reduced to {pendingSeats} seats for the <span className='label big blue'>{workspace.name}</span> workspace.<br /></>}
                            Your next payment of <span className={loadingSeatCost && 'loading-spinner'}>{getSeatUpdateCost()}</span> will be due on {getSeatUpdateTime()}. Invoices are available in your <Link to='/billing' className='link'>personal Billing page</Link>.
                          </span>
                          <div className='finish-buttons'>
                            <button className='btn primary' loading={updatingSeats ? 'loading' : undefined} onClick={updateSeatSubscription}>
                              {pendingSeats < getPurchasedSeats() ? 'Remove seats' : 'Add seats'}
                            </button>
                            <button className='btn' onClick={() => setPendingSeats(getPurchasedSeats())}>Cancel</button>
                            <span className='label'>Current payment method: {paymentMethod} <span className='blue-text'><Link className='link' to='/billing#update-payment'>Change</Link></span></span>
                          </div>
                        </>
                      )}
                      {!paymentMethod && (
                        <div className='finish-buttons'>
                          <span className='label big'>Please setup a payment method on your <Link className='link' to='/billing'>Billing Page</Link> before adding additional seats.</span>
                        </div>
                      )}
                    </div>}
                </div>}
              {!workspaceIsPro() && (
                <div className='page-section'>
                  <label className='label big'>This Free workspace includes <span className='label big blue'>{freeSeats} {freeSeats === 1 ? 'seat' : 'seats'}</span>. Please <Link className='link' to='/billing'>upgrade your account to Pro</Link> on your personal billing page to access more.</label>
                </div>
              )}
            </>
          )}
          {hasWorkspacePermission('Workspace Settings') && (
            <>
              <div title='Use these settings to replace the Sizle branding with your own and create a white-label document sharing experience' className='page-section-header'>Customize logo</div>
              <div className='page-section'>
                <CustomBrandingForm />
              </div>
              {customURLEnabled && (
                <>
                  <div title='Use these settings to replace the Sizle branding with your own and create a white-label document sharing experience' className='page-section-header'>Add Custom URL</div>
                  <div className='page-section'>
                    <label className='label big'>
                      Create your own URL for shareable links (we recommend using <label className='label blue'>docs.yourcompany.com</label>)
                    </label><br />
                    <CustomDomainForm />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default WorkspaceSettingsPage
