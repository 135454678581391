import React, { useContext, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { workspaceContext } from 'src/context'
import { humanFileSize } from 'src/utils'
import styles from './StorageLimit.module.scss'

const StorageLimit = () => {
  const { workspace, currentWorkspacePlan } = useContext(workspaceContext)
  const allocatedFilesize = workspace?.presentations_aggregate?.aggregate?.sum?.filesize
  const allocatedFilesizeRatio = Math.min(1, allocatedFilesize / currentWorkspacePlan?.storageLimit)
  const visible = !!(workspace && currentWorkspacePlan.storageLimit)
  const navigate = useNavigate()

  const heading = `${Math.floor((1 - allocatedFilesizeRatio) * 100)}% space remaining`

  const goToBilling = useCallback(() => { navigate('/billing') }, [navigate])

  return (
    <div>
      {visible && (
        <div onClick={goToBilling} className={styles['filesize-limit']}>
          <span className={styles['storage-available']}>
            <div data-tooltip={`Your plan includes ${humanFileSize(currentWorkspacePlan?.storageLimit)} per workspace`}>
              <strong>{heading}</strong>
            </div>
          </span>
          <div className={styles['bar-container']}>
            <div data-tooltip={`${humanFileSize(allocatedFilesize)}/${humanFileSize(currentWorkspacePlan.storageLimit)}`}>
              <div style={
                {
                  width: `${allocatedFilesizeRatio * 100}%`,
                  height: '100%',
                  background: (allocatedFilesizeRatio >= 0.9 && '#FF7575') || (allocatedFilesizeRatio >= 0.7 && '#FFD857') || '#7FF498'
                }
              }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default StorageLimit
