import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { FiUpload } from 'react-icons/fi'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'
import BeatLoader from 'react-spinners/BeatLoader'

const validTypes = [
  'application/pdf',
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/x-mspublisher', // .pub
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'image/bmp',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/gif',
  'text/plain',
  'text/rtf'
]

const Dropzone = ({ element, submit, cancel, isDark = false, result, children, className }) => {
  const [isDragging, setDragging] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (element) {
      element.ondragenter = handleDragEnter
      element.ondragover = handleDragOver
      element.ondragend = handleDragEnd
      element.ondragexit = handleDragExit
      element.ondrop = handleDrop
    }
  }, [element])

  useEffect(() => {
    if (result) finishUpload()
  }, [result])

  const cancelUpload = useCallback((e) => {
    if ((isDragging || isLoading) && !!cancel) {
      e.preventDefault()
      setDragging(false)
      setLoading(false)
      cancel()
    }
  }, [isDragging, isLoading, cancel])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') cancelUpload(e)
  }, [cancelUpload])

  useEffect(() => {
    if (cancel) {
      window.onclick = cancelUpload
      window.onkeydown = handleKeyDown
    }
  }, [cancel, cancelUpload, handleKeyDown])

  const finishUpload = () => {
    setDragging(false)
    setLoading(false)
  }

  const handleDragOver = (e) => {
    if (!e.dataTransfer.types.presentationid) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'move'
    }
  }

  const handleDragEnter = (e) => {
    if (!e.dataTransfer.types.includes('presentationid')) {
      e.preventDefault()
      setDragging(true)
    }
  }

  const handleDrop = async (e) => {
    if (!e.dataTransfer.types.includes('presentationid')) {
      e.preventDefault()
      const files = e.dataTransfer.files

      if (files.length) {
        if (validTypes.indexOf(files[0].type) >= 0) {
          setLoading(true)

          try {
            await submit(files)
          } catch (error) {
            console.error(error)
            toastr.error(t('toastr.Error uploading file'))
          } finally {
            finishUpload()
          }
        } else {
          toastr.error(t('toastr.This file type is not supported'))
          finishUpload()
        }
      }
    }
  }

  const handleDragEnd = (e) => {
    if (!e.dataTransfer.types.includes('presentationid')) {
      e.preventDefault()
      setDragging(false)
    }
  }

  const handleDragExit = (e) => {
    if (!e.dataTransfer.types.includes('presentationid')) {
      e.preventDefault()
      setDragging(false)
    }
  }

  return (
    <Wrapper className={className} onClick={cancelUpload}>
      <DropContent isDragging={isDragging} isDark={isDark}>
        <Loader>
          <BeatLoader size={10} margin={3} color='var(--primary-color)' loading={isLoading} />
        </Loader>
        <DropMessageContainer isLoading={isLoading} isDark={isDark}>
          <Icon color='var(--text-color)' />
          <DropMessage isDark={isDark}>Drop a file here to start</DropMessage>
        </DropMessageContainer>
      </DropContent>
      <ChildrenContainer
        isDragging={isDragging}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragEnd={handleDragEnd}
        onDragExit={handleDragExit}
        onDrop={handleDrop}
        isLoading={isLoading}
      >
        {children}
      </ChildrenContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`;

const DropContent = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: var(--input-background);
  display: ${props => props.isDragging ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  opacity: 0.95;
  backdrop-filter: blur(4px);
  transition: opacity 0.3s ease;
`;


const DropMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 800px;
  height: 200px;
  border: 4px dashed var(--text-color);
  border-radius: 12px;
  background: var(--input-background);
  opacity: ${props => props.isLoading ? 0.5 : 1};
  transition: background 0.3s ease, border-color 0.3s ease, transform 0.3s ease;

  @media only screen and (max-width: 1200px) {
    width: auto;
    height: auto;
    padding: 20px;
  }

  @media only screen and (max-width: 768px) {
    padding: 14px;
    border-width: 2px;
  }
`;


const Icon = styled(FiUpload)`
  width: 30px;
  height: 30px;
  color: var(--text-color);
  transition: color 0.3s ease, transform 0.3s ease;

  @media only screen and (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

const DropMessage = styled.div`
  text-align: center;
  color: var(--text-color);
  font-size: 20px;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`

const ChildrenContainer = styled.div`
  opacity: ${props => props.isDragging ? 0 : 1};
  height: ${props => props.isDragging ? '100vh' : '100%'};
  overflow: ${props => props.isDragging ? 'hidden' : 'visible'};
  pointer-events: ${props => props.isLoading ? 'none' : 'auto'};
`

const Loader = styled.div`
  pointer-events: none;
  position: absolute;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  display: flex;

  .css-0 {
    margin: auto;
  }
`

export default Dropzone
