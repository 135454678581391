import React from 'react'
import styled from '@emotion/styled'
import SVGInline from 'react-svg-inline'

const FooterCreateIcon = ({ icon, name }) => {
  return (
    <Element svg={icon} className={name === 'logout' ? 'icon-size-fix' : 'footer-icons'} />
  )
}

const Element = styled(SVGInline)`
  margin-right: 11px;

  svg {
    path {
     stroke: var(--text-color-light);
    }
  }

  &.footer-icons {
    height: 22px;
    width: 22px;
  
    svg {
      width: 25px;
      height: 22px;
      margin-left: -4px;
      stroke-width: 0.1;
    }
  }
  

  &.icon-size-fix {
    position: relative;
    left: 2px; 
    width: 22px;
    svg {
      transform: scale(0.90);
    }
  }
`

export default FooterCreateIcon
