import React, { useContext, useState } from 'react'
import { ProPopup } from 'src/modules/modals/components/ProPopup'
import { ModeContext, workspaceContext } from '../../../context'

const CustomBrandingForm = () => {
  const { theme, workspace, updateTheme } = useContext(workspaceContext)
  const { mode } = useContext(ModeContext)
  const [tmpImage, setTmpImage] = useState(null)
  const [force, setForce] = useState(Math.random())
  const [loading, setLoading] = useState(false)

  const onImageSet = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader()
    if (!event.target.files[0]) { setTmpImage(null) } else {
      await new Promise((resolve, reject) => {
        fileReader.addEventListener('load', () => {
          setTmpImage(fileReader.result)
          resolve(null)
        })
        fileReader.addEventListener('error', reject)
        fileReader.readAsDataURL(event.target.files[0])
      })
    }
  }
  const onResetForm = async () => {
    setTmpImage(null)
  }
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    event.stopPropagation()
    event.preventDefault()
    const form = event.currentTarget
    await updateTheme((event.currentTarget.banner as HTMLInputElement).files[0])
    // NOTE: S3 replication can take a few moments, so put in asmall delay to give S3 a chance to replicate
    await new Promise((resolve) => setTimeout(resolve, 1000))
    form.reset()
    setForce(Math.random())
    setLoading(false)
  }
  const onResetTheme = async () => {
    setLoading(true)
    await updateTheme(null)
    setForce(Math.random())
    setLoading(false)
  }

  return (
    <div className='custom-branding-form'>
        Below you can replace the default logo with your own to display when sharing files. For best results, use a rectangular image.<br />
        <p></p>
      <form onSubmit={onSubmit} onReset={onResetForm}>
        <ProPopup requiredFeature='customDomains' title='You discovered a feature!' subtitle='This feature requires a Sizle Advanced. View plans below'>
          <div className='image-form'>
            <img src={tmpImage || `/api/workspaces/${workspace.workspace_id}/banner?mode=${mode}&force=${force}`} alt='workspace banner' />
            <input name='banner' onChange={onImageSet} type='file' id='banner-upload' />
            {!tmpImage && (
              <>
                <label htmlFor='banner-upload' className='btn secondary'>Choose a file</label>
                {theme?.has_banner && <button onClick={onResetTheme} name='remove' className={`btn danger ${loading ? 'loading-spinner' : ''}`}>Reset</button>}
              </>
            )}
            {tmpImage && (
              <>
                <button type='submit' className={`btn primary ${loading ? 'loading-spinner' : ''}`}>Save</button>
                <button type='reset' className='btn'>Cancel</button>
              </>
            )}
          </div>
        </ProPopup>
      </form>
    </div>
  )
}

export default CustomBrandingForm
