import React, { useContext } from 'react'
import { WithStore, Slider, Slide } from 'pure-react-carousel'
import SVGInline from 'react-svg-inline'
import styled from '@emotion/styled'
import { checkCircle } from '@sizle-icons'
import useUserContext from 'src/context/UserContext'

const OnboardingSlider = ({ dismissModal }) => {
  const { user } = useUserContext()

  return (
    <SliderContainer name='onboarding-slider'>
      <Slider
        isIntrinsicHeight={true}
        isPlaying={false}
        infinite={false}
        dragEnabled={false}
        touchEnabled={false}
      >
        <Slide index={0}>
          <div className='slide'>
            <h2 className='slide-title'>Let's get started!</h2>
            <center>
              <h3>Transform documents into a data-driven assets with live engagement insights and content analytics.</h3> 
            </center>
            <div className='slide-body'>
              <RecommendationList>
                <Recommendation>
                  <SVGInline className='check' svg={checkCircle} />
                  Create custom short URLs for documents
                </Recommendation>
                <Recommendation>
                  <SVGInline className='check' svg={checkCircle} />
                  See live analytics and content insights
                </Recommendation>
                <Recommendation>
                  <SVGInline className='check' svg={checkCircle} />
                  Get reactions, comments and approvals
                </Recommendation>
                <Recommendation>
                  <SVGInline className='check' svg={checkCircle} />
                  Organize content in secure workspaces
                </Recommendation>
              </RecommendationList>
            </div>
            <div className='btn-group center'>
              <br></br>
              <button className='btn' onClick={dismissModal}>Go to your dashboard</button>
            </div>
          </div>
        </Slide>
      </Slider>
    </SliderContainer>
  )
}

const SliderContainer = styled.div`
  .carousel__slide-focus-ring {
    outline: none;
    border: none;
  }
  .carousel__slider--horizontal {
    outline: none;
    height: 510px;
  }
  .analytics-svg {
    justify-content: center;
    padding-bottom: 35px;
    svg {
      height: 280px;
      width: 280px;
      padding-bottom: 35px;
    }
  }
  .slide {
    height: 90%;
    display: flex;
    flex-direction: column;
  }
  .slide-body {
    padding: 0px 0px 10px 20px;
    font-size: 1.3em;
    font-weight: 400;
    text-align: left;
    flex-grow: inherit;
    color: var(--text-color);
  }
  .slide-footer {
    margin-top: 10px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .slide-header {
    padding: 0 20px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  .check {
    padding-right: 5px;
    padding-top: 5px;
    opacity: 100%;
    scale: 1.6;
  }
  .slide-title {
    padding-top: 20px;
    text-align: center;
    font-size: 2.4em;
    font-weight: 600;
    line-height: 1.2em;
    color: var(--text-color);
  }
  .onboardingDots {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    .carousel__dot {
      border: 0;
      background-color: var(--primary-color);
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-right: 5px;
      background-color: #d8d8d8;
      margin-right: 6px;
      &.carousel__dot--selected {
        background-color: var(--primary-color);
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  @media screen and (max-width: 860px) {
    height: 100%;
    .slide-header {
      font-size: 14px;
      padding: 0 15px;
    }
    .slide-body {
      font-size: 1em;
      padding: 0 15px;
    }
    .slide-title {
      font-size: 26px;
    }
  }
`

const RecommendationList = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
`

const Recommendation = styled.div`
  margin-top: 15px;
  &:first-child {
    margin-top: 15px;
  }
  display: flex;
  flex-direction: row;
  align-items: start;
  padding-right: 15px;
`

export default WithStore(OnboardingSlider, state => ({
  currentSlide: state.currentSlide,
  totalSlides: state.totalSlides
}))
