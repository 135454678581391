import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/core';
import { toastr } from 'react-redux-toastr';

import { sendAmplitudeData } from 'src/utils/amplitude';
import { useModal } from 'src/modules/modals';
import { workspaceContext, useUserContext, presentationContext } from 'src/context';

const PresentationOptionsPopupMenu = ({
  title,
  presentationId,
  closePopup,
  onClickAnalytics,
  openTrackingEnabled,
  emailNotificationsEnabled,
  mouseX,
  mouseY
}) => {
  const { workspace, deleteDocument, renameDocument, showStorageFilledPrompt } = useContext(workspaceContext);
  const { clonePresentation } = useContext(presentationContext);
  const { hasWorkspacePermission } = useContext(workspaceContext);
  const { workspaceIndex } = useUserContext();
  const [renameLoading, setRenameLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const menuRef = useRef(null);
  const { isOpen: isOpenRename, onOpen: onOpenRename, onClose: onCloseRename } = useDisclosure();
  const { setModal } = useModal();
  const { t } = useTranslation();

  // Adjust the position of the menu based on the mouse coordinates
  const menuPosition = { x: mouseX, y: mouseY };

  const handleCopyButtonClick = async () => {
    closePopup();
    if (workspace.isOverStorage || workspace.isOverFileLimit) {
      showStorageFilledPrompt();
    } else {
      sendAmplitudeData('DUPLICATING_DOCUMENT', { presentationId });
      await clonePresentation(presentationId);
    }
  };

  const onClickOpenShareModal = useCallback(() => {
    closePopup();
    sendAmplitudeData('OPENING_SHARE_MODAL');
    setModal('share_presentation', {
      title,
      presentationId,
      openTrackingEnabled,
      emailNotificationsEnabled,
    });
  }, [closePopup, emailNotificationsEnabled, openTrackingEnabled, presentationId, setModal, title]);

  const updateTitle = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.currentTarget);
    const newTitle = formData.get('presentationTitle')?.toString();
    if (!newTitle?.length) return;

    setRenameLoading(true);
    await renameDocument(presentationId, newTitle);
    setRenameLoading(false);
    onCloseRename();
    closePopup();
  }, [closePopup, onCloseRename, presentationId, renameDocument]);

  const handleDeleteButtonClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteLoading(true);
    await deleteDocument(presentationId);
    setDeleteLoading(false);
    toastr.success('File has been deleted');
    closePopup();
  };

  return (
    <MenuContainer style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, position: 'fixed' }} ref={menuRef}>
      {hasWorkspacePermission('Share Documents') && (
        <MenuItem onClick={onClickOpenShareModal}>
          {t('Share')}
        </MenuItem>
      )}
      {hasWorkspacePermission('Analytics & Reports') && (
        <MenuItem onClick={onClickAnalytics}>
          {t('Insights')}
        </MenuItem>
      )}
      {hasWorkspacePermission('Add & Edit Documents') && (
        <>
          <StyledLink to={`/s/${workspaceIndex}/presentation/${presentationId}/edit`}>
            <MenuItem>{t('Preview')}</MenuItem>
          </StyledLink>

          <MenuItem onClick={onOpenRename}>{t('Rename')}</MenuItem>
          {isOpenRename && (
            <RenameForm onSubmit={updateTitle}>
              <input
                className={renameLoading ? 'loading-spinner' : ''}
                name="presentationTitle"
                defaultValue={title}
                autoFocus
              />
            </RenameForm>
          )}

          <MenuItem onClick={handleCopyButtonClick}>{t('Duplicate')}</MenuItem>

          <MenuItem
            className={deleteLoading ? 'loading-spinner' : ''}
            onClick={handleDeleteButtonClick}
          >
            {t('Delete')}
          </MenuItem>
        </>
      )}
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  position: fixed; /* Ensures it is anchored to the viewport */
  background-color: var(--background-color);
  border-radius: 6px;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  padding: 0;
  top: -120px;
  right: 150px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: opacity 0.2s ease, transform 0.2s ease;
  width: max-content;
  min-width: 120px;
  max-width: 90vw;
  transform-origin: top left;
`;

const MenuItem = styled.button`
  background: none;
  font-size: 0.85rem;
  border: none;
  color: var(--text-color);
  padding: 5px 0px 5px 12px;
  text-align: left;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: var(--background-hover-color);
  }

  &:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

const RenameForm = styled.form`
  margin: 8px 0;
  position: relative;
  input {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    color: var(--text-color);
    background-color: var(--input-background-color);
    font-size: 14px;
    box-sizing: border-box;
  }
`;

export default PresentationOptionsPopupMenu;
