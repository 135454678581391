import React from 'react';

const HelpButton = () => {
  const handleClick = () => {
    window.open('https://sizle.io/', '_blank', 'noopener,noreferrer');
  };

  return (
    <button 
      className='btn extra big secondary'
      onClick={handleClick}
    >
      Get help
    </button>
  );
};

export default HelpButton;
